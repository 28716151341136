import React from "react"
import { Helmet } from "react-helmet"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../styles/style.css"
import Web from "../../../static/images/icons/web/icon29.svg"

const Soluciones1 = () => {
  const data = useStaticQuery(graphql`
    query Images_web {
      image: allFile(
        filter: { relativeDirectory: { eq: "kit-digital" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Soluciones Web Kit Digital | Qualoom</title>
        <meta name="title" content="Soluciones Web Kit Digital | Qualoom"/>
        <meta name="description" content="Soluciones del Kit Digital para la creación de una Página para tu empresa y los servicios de Posicionamiento Web. ¡Infórmate!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/kit-digital/soluciones-web/"/>
        <link rel="canonical" href="https://www.qualoom.es/kit-digital/soluciones-web/"/>
        
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/kit-digital/soluciones-web/"/>
        <meta property="og:title" content="Soluciones Web Kit Digital | Qualoom"/>
        <meta property="og:description" content="Soluciones del Kit Digital para la creación de una Página para tu empresa y los servicios de Posicionamiento Web. ¡Infórmate!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/kit-digital/soluciones-web/"/>
        <meta property="twitter:title" content="Soluciones Web Kit Digital | Qualoom"/>
        <meta property="twitter:description" content="Soluciones del Kit Digital para la creación de una Página para tu empresa y los servicios de Posicionamiento Web. ¡Infórmate!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/kit-digital/soluciones-web/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Kit Digital", "item": {"@id": "https://www.qualoom.es/kit-digital/", "name": "Kit Digital"}},
              {"@type":"ListItem", "position":"3", "name":"Soluciones Web", "item": {"@id": "https://www.qualoom.es/kit-digital/soluciones-web/", "name": "Soluciones Web"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-kit">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Soluciones Web</h1>
              <p>Soluciones para la creación de una página web para tu empresa y/o la prestación de servicios para su posicionamiento en internet.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Sitio web y Presencia en Internet</h2>
          <div className="soluciones-info">
            <img src={Web} alt="Sitio web y presencia en internet"></img>
            <p><b>Objetivo principal:</b> Expansión de la presencia en internet de tu empresa mediante la creación de una página web y/o la prestación de servicios que proporcionen posicionamiento básico en internet.</p>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Servicios ofrecidos</p></div>
            <div className="row">
              <div className="normal-cell-1">
                <p><b>Dominio:</b> la solución incluye el dominio de tu web durante un plazo mínimo de doce meses. La titularidad del dominio será tuya por completo.</p>
                <p><b>Hosting:</b> también tendrás a tu disposición el alojamiento de la página web durante un plazo mínimo de doce meses.</p>
                <p><b>Diseño de la página web:</b> como parte del servicio tendrás la estructura de la web, con un mínimo de 3 apartados. Se consideran páginas o apartados de una web, elementos tales como: página de inicio (Landing Page), presentación de la empresa, formulario de contacto, descripción de productos, datos de contacto, mapa del sitio (sitemap), etc.</p>
                <p><b>Web responsive:</b> tu web se adaptará perfectamente a cualquier tipo de dispositivo.</p> 
                <p><b>Accesibilidad:</b> el diseño de tu web cumplirá con los criterios de conformidad de nivel AA de las Pautas WCAG-2.1.</p> 
                <p><b>Posicionamiento básico en internet:</b> la información de tu pyme será indexable por los principales motores de búsqueda (On-Page).</p>
                <p><b>Autogestionable:</b> dispondrás también de una plataforma de gestión de contenidos para que puedas modificar los contenidos de tu web sin necesidad de ayuda.</p>
                <p><b>Optimización de la presencia en buscadores (SEO básico):</b> Análisis de palabras clave, SEO On-Page de 2 páginas o apartados, indexación y jerarquización del contenido.</p>
              </div>
            </div>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Porcentajes de ejecución por fases</p></div>
            <div className="row">
              <div className="normal-cell-2"><p>Primera fase 70%</p></div>
              <div className="normal-cell-2"><p>Segunda fase 30%</p></div>
            </div>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Importe máximo del Bono por segmento</p></div>
            <div className="row">
              <div className="normal-cell-3"><p>Segmento I<br/>2.000€</p></div>
              <div className="normal-cell-3"><p>Segmento II<br/>2.000€</p></div>
              <div className="normal-cell-3"><p>Segmento III<br/>2.000€</p></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Soluciones1
